@import '../../colors';

.overviewlist-card {
  border-radius: 10px;
  box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  transition: 0.3s ease all;
  width: 100%;
  aspect-ratio: 1;

  .overviewlist-card-inner {
    position: relative;
    border-radius: 5px !important;
    overflow: hidden;
    background-size: cover;
    width: 100%;
    aspect-ratio: 1;

    .overlay {
      background: linear-gradient(
                      180deg,
                      rgba(0, 0, 0, 0.27) 0%,
                      rgba(0, 0, 0, 0) 50%,
                      rgba(0, 0, 0, 0.27) 100%
      );
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    h3 {
      color: white;
      margin: 0;
      position: absolute;
      bottom: 0px;
      font-size: 14px;
      font-weight: 600;
      text-align: center;

      display: table; /* keep the background color wrapped tight */
      padding: 8px;
      width: 100%;
      background: linear-gradient(
                      180deg,
                      rgba(0, 0, 0, 0.5) 50%,
                      rgba(0, 0, 0, 0.5) 100%
      );
      -moz-border-radius-bottomleft: 8px;
      -moz-border-radius-bottomright: 8px;

    }

    h3 span {
      opacity: 100%;
      line-height: 1.4em;

      /* autoprefixer: off */
      text-overflow: ellipsis;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      min-height : 2.8em;
    }

    img {
      display: block;
    }

    .icon {
      color: white;
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}
