@import '../../colors.scss';

.coming-soon {
  h1 {
    text-align: center;
    font-size: 26px !important;
    color: $trabu-teal;
    margin-top: 0;
  }

  p {
    color: #606060;
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
  }

  img {
    margin-top: 40px;
  }
}