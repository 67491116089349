@import "../../colors";

.ideas-page {
  padding: 0;
  background-position: center !important;
  height: 100%;
  display: flex;
  flex-direction: column;

  .ideas-top-block {
    background: $trabu-teal;
    text-align: center;
    padding: max(env(safe-area-inset-top), 20px) 30px 40px;
    display: flex;
    align-items: center;
    gap: 20px;

    .btn {
      height: 40px !important;
      width: 40px !important;
      flex-shrink: 0;
      position: relative;
    }

    @keyframes pulse-indicator {
      0% {
        background: #E1E2EF;
      }
      50% {
        background: #169873;
      }
      100% {
        opacity: #E1E2EF;
      }
    }

    .btn-filters.active::after {
      content: '';
      width: 12px;
      height: 12px;
      position: absolute;
      top: -1px;
      right: -1px;
      background: #E1E2EF;
      border-radius: 10px;
      box-shadow: -3px 3px 5px 0px rgba(0, 0, 0, 0.20);
      transition: 0.1s ease all;
      animation: pulse-indicator 2s infinite;
    }

    img {
      height: 40px !important;
      max-height: 40px !important;
      flex-shrink: 0;
    }

    .tabs-2 {
      margin-top: 0;
    }
  }

  .ideas-container {
    background: white;
    margin-top: -20px;
    flex-grow: 1;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    overflow-y: scroll;
    grid-template-rows: repeat(auto-fill, 1fr);
    scroll-snap-type: y mandatory;
    scroll-snap-align: center;
    -ms-overflow-style: none;
    scrollbar-width: none;
    position: relative;

    &::-webkit-scrollbar {
      display: none;
    }

    .idea {
      height: 100%;
      position: relative;
      scroll-snap-align: center;
      scroll-snap-stop: always;

      .idea-gallery,
      .image-gallery-content,
      .image-gallery-slide-wrapper,
      .image-gallery-slide,
      .image-gallery-swipe,
      .image-gallery-slides,
      .image-gallery-slide{
        height: 100%;
      }

      &.last-item {
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: italic;
        color: #606060;
        background-image: url("../../../public/transparent-icon.png");
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: -100px -100px;
      }

      &.loading {
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url("../../../public/transparent-icon.png");
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: -100px -100px;
        
        svg {
          color: $trabu-orange;
        }
      }

      h2 {
        color: white;
        position: absolute;
        font-weight: 600;
        bottom: 30px;
        left: 30px;
        margin: 0;
        font-size: 26px;
        text-wrap: balance;
        right: 90px;
      }

      .btn {
        position: absolute;
        bottom: 35px;
        right: 30px;
      }

      .image-gallery-bullets {
        bottom: 15px;

        button.image-gallery-bullet {
          box-shadow: none;
          opacity: 0.3;
          background-color: white;
          border-radius: 20px;
          transition: 0.3s ease all;

          &.active {
            opacity: 0.6;
            transform: none;
            width: 20px;
            transition: 0.3s ease all;
          }
        }
      }

      .image-gallery-slide {
        img {
          height: 100%;
          object-fit: cover;
          object-position: center;
          position: relative;
        }

        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.4) 100%);
          z-index: 3;
        }
      }
    }
  }
}

.ideas-heading {
  .page-heading-content {
    padding-bottom: 10px;
    padding-top: 20px;
  }
}

.ideas-filter {
  width: calc(100% - 90px);
  background: white;
  border-radius: 20px;
  box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.25);
  padding: 0 15px;
  margin: 0 30px 20px 30px;

  .ideas-filter-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    font-size: 14px;

    div {
      display: flex;
      align-items: center;

      svg {
        color: $trabu-orange;
        margin-right: 5px;
      }
    }
  }

  .ideas-filter-content {
    padding-bottom: 15px;

    label {
      font-size: 14px;
      font-weight: 600;
      color: $trabu-teal;
      display: block;

      &:not(:first-child) {
        margin-top: 20px;
      }
    }

    .slider {
      .MuiSlider-root {
        margin-left: 10px;
        margin-right: 10px;
        width: calc(100% - 20px);

        .MuiSlider-rail {
          height: 6px;
          background: #DDDDDD;
          opacity: 1;
          border-radius: 0;
        }

        .MuiSlider-track {
          background: $trabu-orange;
          border: 0;
          height: 6px;
        }

        .MuiSlider-mark {
          width: 9px;
          height: 9px;
          border-radius: 10px;
          background: #DDDDDD;

          &.MuiSlider-markActive {
            background: $trabu-orange;
          }
        }

        .MuiSlider-thumb {
          box-shadow: none !important;
          background: $trabu-orange;
          width: 20px;
          //position: relative;
        }
      }
    }

    small {
      color: #999;
      font-size: 12px;
      line-height: 12px;
    }
  }
}

.create-trip-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}






