@import '../../colors';

.overviewheader-card {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  transition: 0.3s ease all;
  width: 100%;
  aspect-ratio: 1;
  background-size: cover;
}

.titletext {
  font-size: 1.2em;
  background-color: #1D252D;
}

.titletext span {
  padding: 5px;
  font-size: 1.2em;
  background: linear-gradient(
                  180deg,
                  rgba(0, 0, 0, 0.15) 20%,
                  rgba(0, 0, 0, 0.15) 40%
  );
  opacity: 100%;


}