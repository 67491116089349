@import "../../colors";

.statusbar-padding{
  background: $trabu-teal;
  height: 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 10;
}

.page-heading{
  background: $trabu-teal;

  .page-heading-content{
    display: flex;
    font-size: 26px;
    align-items: center;
    padding: max(20px, env(safe-area-inset-top, 20px)) 30px 20px 30px;

    svg{
      color: $trabu-orange;
      margin-right: 10px;
    }

    h1{
      color: white;
      margin: 0;
      font-size: 26px;
    }
  }

  .heading-rounded-container{
    background: white;
    width: 100%;
    height: 20px;
    display: block;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: -10px -10px 20px 0px rgba(0, 0, 0, 0.25);
  }

  .page-heading-inner{
    padding: 0 30px;
    padding-bottom: 30px;
  }

  overflow: hidden;
}