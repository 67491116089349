@import '../../colors';

.email-confirmation {
  .page-header {
    background: $trabu-teal;
    text-align: center;
    padding: max(env(safe-area-inset-top, 0), 20px) 0 40px;

    img {
      max-height: 30px;
    }
  }

  .page-content {
    background: white;
    margin-top: -30px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: -10px -10px 20px 0px rgba(0, 0, 0, 0.25);
    min-height: calc(100vh - 67px);
    overflow-y: scroll;
    padding: 30px;
    box-sizing: border-box;
    position: relative;
  }

  .trabu-icon {
    position: absolute;
    top: -100px;
    left: -100px;
    opacity: 0.03;
    z-index: 0;
  }

  h1 {
    color: $trabu-teal;
    margin: 0;

    span {
      display: block;
      color: $trabu-orange;
    }
  }

  .MuiCircularProgress-root {
    color: $trabu-orange;
    margin-top: 20px;
  }

  p {
    color: #606060;
    font-size: 14px;
  }
}