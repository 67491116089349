@import "colors";

.btn {
  width: 100% !important;
  border-radius: 100px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
  text-transform: none !important;
  transition: 0.2s ease all !important;
  letter-spacing: 0 !important;

  svg {
    width: 16px;
  }
}

.btn:disabled {
  opacity: 0.6;
}

.btn-icon {
  width: 50px !important;
  height: 50px;
  min-width: unset !important;
  font-size: 18px !important;
  box-shadow: 5px 5px 15px 10px rgba(0, 0, 0, 0.1) !important;
  padding: 0;

  svg {
    width: 22px;
  }
}

.btn-orange {
  background: $trabu-orange !important;
  box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.15) !important;
  color: white !important;
}

.btn-text {
  color: $trabu-teal !important;
}

.btn-teal {
  background: $trabu-teal !important;
  box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.15) !important;
  color: white !important;
}

.btn-border {
  background: transparent !important;;
  border: 2px solid $trabu-teal !important;
  color: $trabu-teal !important;
}

.btn-border-red {
  color: #D81E5B !important;
  border: 2px solid #D81E5B !important;
}

.btn-red {
  background: #D81E5B !important;
  color: white !important;
}

.btn-white {
  background: white !important;
  box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.15) !important;
  color: $trabu-teal !important;
}

.btn-long {
  height: 50px !important;
  font-size: 14px !important;

  svg {
    font-size: 16px !important;
  }
}

.btn-block {
  display: block !important;
}

.btn-short {
  width: unset !important;
  padding: 10px 20px !important;
  font-size: 13px !important;
}

.btn-full-width {
  width: 100% !important;
}

.btn-small {
  font-size: 12px !important;
  height: 40px !important;
}

.btn-white-border {
  background: transparent !important;
  border: 2px solid white !important;
  color: white !important;
}

/* Form Fields */

.text-field {
  display: block;
  width: 100%;

  .MuiFilledInput-root {
    border: 0 !important;
    background: #fff !important;
    box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.10);
    border-radius: 10px;
    padding-left: 20px;
    height: 50px;

    .MuiInputAdornment-root {
      margin-top: 0 !important;
    }

    svg {
      color: $trabu-teal;
      font-size: 17px;
    }

    input {
      box-shadow: none;
      padding: 0 20px 0 10px;
      font-size: 12px;
      color: #2f2f2f;
      font-family: "Poppins", sans-serif;

      &::placeholder {
        color: #999999;
        opacity: 1;
      }

      &[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
      }
    }

    &:before, &:after {
      display: none !important;
    }
  }
}

.swal2-container {
  padding: 30px;
  z-index: 9999999;

  .swal2-modal, .swal2-title, .swal2-text {
    font-family: "Poppins", sans-serif !important;
  }

  .swal2-confirm {
    border-radius: 100px !important;
    background: $trabu-orange;
    font-size: 14px !important;
    font-weight: 500 !important;
    padding: 15px 30px;
    font-family: "Poppins", sans-serif !important;

    &:focus {
      box-shadow: none;
    }
  }

  .swal2-cancel, .swal2-deny {
    border-radius: 100px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    padding: 15px 30px;
    font-family: "Poppins", sans-serif !important;

    &:focus {
      box-shadow: none;
    }
  }
}

.trabu-app-container {
  padding: 30px;
  padding-top: 20px;
}

.MuiModal-root {
  z-index: 9999999 !important;
  font-family: "Poppins", sans-serif;

  .MuiBackdrop-root {
    background: rgba(0, 0, 0, 0.7) !important;
    backdrop-filter: blur(3px);
  }

  .MuiDialogTitle-root {
    font-weight: 600;
    color: $trabu-teal;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
  }

  .MuiDialogContent-root {
    font-size: 14px !important;
    color: #606060 !important;

    label {
      font-weight: 600;
      color: $trabu-teal;
      margin-top: 20px;
      display: block;
    }

    input {
      background: rgba(0,0,0,0.1);
      box-shadow: none;
      font-size: 13px;
      border-radius: 5px;
      padding: 10px 15px;
      color: $trabu-teal;
      margin-top: 5px;

      &::placeholder {
        color: #999;
      }
    }
  }
}

.modal-content {
  background: white;
  width: calc(100vw - 60px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
  max-height: 80vh;
  overflow-y: scroll;

  h3 {
    color: $trabu-teal;
    font-size: 16px;
    margin-bottom: 10px;
  }

  label {
    color: $trabu-teal;
    font-weight: 600;
    font-size: 12px;
    display: block;
  }

  select {
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    background: rgba(0, 0, 0, 0.1);
    border: 0;
    font-family: "Poppins", sans-serif;
    margin-top: 10px;
    font-size: 12px;
  }

  .separator {
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;

    .line1, .line2 {
      flex-grow: 1;
      height: 1px;
      background: #ddd;
    }

    .separator-text {
      font-size: 12px;
      color: #999;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.confetti-bucketlist {
  position: fixed;
  width: 80px;
  bottom: -10px;
  left: 37.5%;
  transform: translateX(-50%);
  z-index: 100;
}

.trabu-tabs {
  width: calc(100% - 10px);
  background: #F4F4F4;
  border-radius: 100px;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 20px;
  padding: 5px;

  &:before {
    content: '';
    width: calc(50% - 5px);
    height: calc(100% - 10px);
    position: absolute;
    top: 5px;
    border-radius: 100px;
    background: white;
    transition: 0.3s ease all;
    box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.15);
    left: 5px;
  }

  &.second:before {
    transform: translateX(100%);
  }

  button {
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    width: 50%;
    border-radius: 100px;
    color: $trabu-teal;
    font-weight: 500;
    text-transform: none;
    transition: 0.3s ease all;
    padding: 8px;
    background: transparent;

    .MuiTouchRipple-root {
      display: none;
    }
  }
}

.MuiChip-root {
  font-family: "Poppins", sans-serif !important;
  color: #606060 !important;

  svg {
    color: $trabu-orange !important;
    font-size: 16px;
    margin-left: 15px !important;
    margin-right: -10px !important;
  }

  .MuiChip-label {
    padding: 0 15px !important;
  }
}

.tabs-2 {
  width: 100%;
  background: #F4F4F4;
  height: 40px;
  border-radius: 30px;
  position: relative;
  margin-top: 20px;
  display: flex;
  justify-content: center;

  button {
    width: calc((100% - 10px) / 2);
    height: 30px;
    margin-top: 5px;
    border-radius: 20px;
    border: 0;
    background: transparent;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #999;
    font-size: 12px;
    z-index: 2;
    position: relative;
    transition: 0.3s ease all;

    svg {
      font-size: 13px;
      vertical-align: sub;
    }
  }

  &.active-1 button:first-child, &.active-2 button:nth-child(2), &.active-3 button:last-child {
    color: $trabu-teal;

    svg {
      color: $trabu-orange;
    }
  }

  &:before {
    content: '';
    background: white;
    height: 30px;
    border-radius: 40px;
    position: absolute;
    box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.10);
    width: calc((100% - 10px) / 2);
    z-index: 1;
    top: 5px;
    transition: 0.3s ease all;
  }

  &.active-1:before {
    left: 5px;
  }

  &.active-2:before {
    left: calc((100% - 10px) / 2 + 5px);
  }
}

.masonry {
  width: -webkit-fill-available !important;
}

.password-checklist {
  font-size: 14px;
  margin-top: 15px;

  svg {
    font-size: 20px;
    margin-right: 5px;
  }

  .valid {
    svg {
      color: $green;
    }

    color: $trabu-teal;
  }

  .invalid svg {
    color: $red;
  }
}

.ion-cancel {
  --color: #163D4A;
}

.ion-save {
  --color: #EB7E45;
}

.ion-reset {
  --color: #F03A47;
}

ion-datetime {
  max-width: 100%;
}

ion-toolbar.ios {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

ion-header.md, ion-header.ios {
  --border-width: 0 !important;
  box-shadow: 5px 5px 15px 10px rgba(0, 0, 0, 0.1) !important;
}

ion-button.in-toolbar {
  font-size: 14px !important;
  font-weight: 500 !important;
  text-transform: none !important;
  letter-spacing: 0 !important;
  color: $trabu-teal !important;
}

.tabs-top-block {
  background: $trabu-teal;
  text-align: center;
  padding: max(env(safe-area-inset-top), 20px) 30px 40px;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: -20px;

  .btn {
    height: 40px !important;
    width: 40px !important;
    flex-shrink: 0;
  }

  img {
    height: 40px !important;
    max-height: 40px !important;
    flex-shrink: 0;
  }

  .tabs-2 {
    margin-top: 0;
  }
}

.rounded-content {
  box-shadow: -10px -10px 20px 0px rgba(0, 0, 0, 0.25);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;

  .background-content {
    display: none !important;
  }
}
.hidden-tab-bar {
  display: none !important;
}