@import '../../colors';

.location-info-notch {
  height: env(safe-area-inset-top, 0);
  background: white;
  border-bottom: 1px solid #E5E5E5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 3;
}

.location-info-header {
  background-size: cover !important;
  background-position: center !important;
  height: 350px;
  position: relative;

  .details-buttons {
      position: absolute;
      right: 30px;
      bottom: 50px;
      z-index: 5;
    }

  .location-header-gallery img {
    height: 350px !important;
    width: 100%;
    background-size: cover;
    background-position: center;
  }

  .location-header-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(201, 24, 24, 0.4);
    z-index: 4;
  }

  .image-gallery {
    .image-gallery-slide {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
        z-index: 3;
      }
    }

    img {
      height: 250px;
      object-fit: cover;
    }

    .image-gallery-bullets {
      bottom: 30px;

      button.image-gallery-bullet {
        box-shadow: none;
        opacity: 0.3;
        background-color: white;
        border-radius: 20px;
        transition: 0.3s ease all;

        &.active {
          opacity: 0.6;
          transform: none;
          width: 20px;
          transition: 0.3s ease all;
        }
      }
    }
  }
}

.location-information {
  border-radius: 20px;
  box-shadow: -10px -10px 20px 0px rgba(0, 0, 0, 0.25);
  margin-top: -20px;
  background: white;
  min-height: calc(100 * var(--vh) - 360px);
  padding: 30px;
  position: relative;
  z-index: 2;

  h1 {
    color: $trabu-teal;
    margin: 0;
    font-size: 18px;
  }

  h2{
    color: #606060;
    margin: 0;
    font-size: 12px;
    font-weight: 400;
  }

  .buttons{
    margin-top: 20px;
  }

  .location-description{
    color: #606060;
    font-size: 14px;
    margin-top: 20px;
    display: block;
  }

  .section-title{
    display: flex;
    align-items: center;
    margin-top: 30px;

    svg{
      color: $trabu-orange;
      font-size: 18px;
      margin-right: 7px;
    }

    h3{
      color: $trabu-teal;
      margin: 0;
      font-size: 16px;
    }
  }

  .recommended-months-wrapper, .recommended-ages-wrapper{
    height: 90px;
    position: relative;

    .recommended-months-container, .recommended-ages-container {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100vw - 30px);
      overflow-x: scroll;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }

      .recommended-months, .recommended-ages {
        display: flex;

        .month, .age {
          width: 90px;
          height: 90px;
          background: rgba(0, 0, 0, 0.06);
          border-radius: 10px;
          margin-right: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;

          &.age{
            width: 70px;
          }

          span {
            font-size: 26px;
            display: block;
            line-height: 35px;
          }

          h4 {
            color: #606060;
            font-size: 12px;
            font-weight: 600;
            margin: 0;
            display: block;
            text-transform: capitalize;
          }
        }

        &:after{
          content: '';
          width: 20px;
          flex-shrink: 0;
        }
      }
    }
  }

  .tabs-3, .trabu-tabs{
    margin-top: 30px;
  }

  .location-child{
    display: flex;
    align-items: center;
    margin-top: 20px;

    img{
      width: 80px;
      height: 80px;
      border-radius: 5px;
      object-fit: cover;
      object-position: center;
      margin-right: 15px;
      flex-shrink: 0;
    }

    .location-child-info{
      h5{
        color: $trabu-teal;
        font-size: 16px;
        margin: 0;
        margin-bottom: 5px;
      }

      p{
        color: #606060;
        font-size: 12px;
        margin: 0;
      }
    }
  }
}