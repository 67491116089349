@import "../../colors";

.landing-page {
  width: 100vw !important;
  background-color: $trabu-teal;
  height: calc(100 * var(--vh));

  .video-background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    //z-index: -;
    opacity: 0.2;

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: calc(100 * var(--vh));
      object-fit: cover;
    }
  }

  .landing-page-content {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: left;
    box-sizing: border-box;

    img.logo {
      width: 40vw;
      margin-bottom: 50px;
    }

    .landing-page-trabu-shape {
      width: 100vw;
      position: fixed;
      top: -60px;
      left: -60px;
      opacity: 0.07;
    }

    h1 {
      color: white;
      font-size: 2.5rem;
      margin-bottom: 0;
      line-height: 145%;
      margin-top: 0;
    }

    p {
      color: white;
      font-size: 0.8rem;
      margin-top: 20px;
      margin-bottom: 50px;
      font-weight: 300;
      line-height: 200% !important;
    }
  }

  .react-modal-sheet-backdrop {
    z-index: 2 !important;
  }

  .react-modal-sheet-container {
    z-index: 3 !important;
  }
}