@import '../../colors';

.error {
  border: 2px solid red;
}

.feedback-button {
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  background: rgba(0, 0, 0, 0.4);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 90px;
  z-index: 2000 !important;
  line-height: 90px;
  text-align: center;
  color: white;
  font-size: 18px;
}

.feedback-modal {
  z-index: 2001 !important;

  .modal-content {
    h2 {
      color: $trabu-teal;
      font-size: 18px;
      font-weight: 600;
      margin: 0;
    }

    label {
      margin-top: 15px;
      margin-bottom: 5px;
    }

    input,
    textarea {
      box-shadow: none;
      background: rgba(0, 0, 0, 0.07);
      font-size: 14px;
      padding: 10px 15px;

      &:disabled {
        opacity: 0.6;
      }

      &::placeholder {
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}

.widget__actor {
  position: fixed !important;
  left: 0 !important;
  right: unset !important;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  background: rgba(0, 0, 0, 0.4);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 90px;
  z-index: 2000 !important;
  line-height: 90px;
  text-align: center;
  color: white;
  font-size: 18px;
}

#sentry-feedback {
  .widget__actor {
    display: none !important;
  }
}