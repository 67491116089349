@import '../../../colors';

.experience-info {
  padding: 20px 30px;

  h2 {
    margin-top: 30px;
  }

  .additional-image {
    aspect-ratio: 16/9;
    border-radius: 10px;
    width: 100%;
    object-fit: cover;
    object-position: center;

    &.small {
      border-radius: 5px;
      aspect-ratio: 1;
    }
  }

  .info {
    font-size: 12px;
    color: $trabu-teal;
    display: block;
    margin-bottom: 10px;

    b {
      font-weight: 600;
    }

    svg {
      color: $trabu-orange;
      vertical-align: text-top;
      font-size: 14px;
      margin-right: 4px;
    }
  }

  p {
    font-size: 13px;
    color: #606060;
    line-height: 180%;
  }

  .MuiChip-root {
    margin-right: 5px;
    margin-bottom: 8px;
    font-size: 12px;
  }

  h2 {
    color: $trabu-teal;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .map-container {
    border-radius: 10px;
    overflow: hidden;
  }
}