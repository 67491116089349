@import '../../../colors';

.overview {
  margin-bottom: 40px;
}
.summary {
  display: flex;
  align-items: 'center';
}
.css-o4b71y-MuiAccordionSummary-content {
  margin: 0px !important;
}
.trip-overview-header {
  margin-bottom: 5px;
}
.trip-overview-footer {
  margin-top: 5px;
}
.trip-overview-header-footer {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #fff;
  padding: 10px 2px;
  border-radius: 10px;

  span {
    font-size: 14px;
    font-weight: 600;
  }
  svg {
    font-size: 18px;

    margin-right: 5px;
    flex-shrink: 0;
  }
}
