@import '../../colors';

.authenticated-onboarding {
  .page-header {
    background: $trabu-teal;
    text-align: center;
    padding: max(env(safe-area-inset-top, 0), 20px) 0 40px;

    img {
      max-height: 30px;
    }
  }

  .onboarding-content {
    background: white;
    margin-top: -30px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: -10px -10px 20px 0px rgba(0, 0, 0, 0.25);
    min-height: calc(100vh - 67px);
    overflow-y: scroll;
    padding: 30px;
    box-sizing: border-box;
    position: relative;

    input[type="text"] {
      all: unset;
      position: relative;
      z-index: 0;
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
      border-bottom: 2px solid #ddd;
      color: $trabu-teal;
      transition: 0.3s ease all;
      margin-top: 20px;

      &:focus {
        border-bottom: 2px solid $trabu-orange;
      }

      &::placeholder {
        color: #999;
      }
    }

    .trabu-icon {
      position: absolute;
      top: -100px;
      left: -100px;
      opacity: 0.03;
      z-index: 0;
    }

    h1 {
      color: $trabu-teal;
      margin: 0;

      svg {
        font-size: 40px !important;
        vertical-align: middle;
        color: $trabu-orange;
        filter: drop-shadow(3px 3px 5px rgba(0, 0, 0, .2));
      }
    }

    h2 {
      margin: 0;
      color: $trabu-teal;
      font-weight: 400;
      font-size: 14px;
      margin-top: 5px;

      svg {
        color: $trabu-orange;
        width: 18px;
        vertical-align: bottom;
      }
    }
  }

  .onboarding-discover {
    position: relative;
    padding-bottom: 40px;

    .btn-ready {
      position: fixed;
      right: 20px;
      bottom: 20px;
      width: fit-content !important;
      padding-left: 20px;
      padding-right: 20px;

      &:disabled {
        background: #D9D9D9 !important;
        color: #999999 !important;
        opacity: 1 !important;
      }
    }
  }

  .onboarding-discover-item {
    position: relative;
    border-radius: 10px;
    overflow: hidden;

    img {
      width: 100%;
      object-fit: cover;
      object-position: center;
      display: block;
    }

    .content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px;
      box-sizing: border-box;

      .type {
        color: white;
        font-size: 20px;
        align-self: flex-end;
      }

      .title {
        align-self: flex-start;
        text-wrap: balance;
        color: white;
        margin: 0;
        text-align: left;
        font-size: 14px;
        font-weight: 600;
      }
    }

    .reaction {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      color: $trabu-orange;
      font-size: 80px;
      visibility: hidden;
      opacity: 0;
      transition: 0.2s ease all;

      svg {
        filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, .1));
      }

      &.saved {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .selected-airports {
    display: block;
  }

  .empty-airport {
    font-size: 12px;
    display: flex;
    align-items: center;
    color: $trabu-teal;
    background: #F2F2F2;
    padding: 3px 5px;
    border-radius: 5px;
    transition: 0.3s ease all;
    width: fit-content;
    margin-top: 5px;
  }

  .selected-airport {
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    color: white;
    background: $trabu-orange;
    padding: 3px 5px;
    border-radius: 5px;
    transition: 0.3s ease all;
    width: fit-content;
    margin-top: 5px;
    margin-right: 5px;

    svg {
      margin-right: 3px;
    }
  }

  .airports {
    height: calc(100vh - 570px);
    overflow-y: scroll;
    overflow-x: visible;
    margin-left: -30px;
    margin-right: -30px;
    margin-top: 0px;
    padding: 0 30px;
    padding-bottom: 40px;
    padding-top: 20px;
  }

  .airport {
    padding: 10px;
    box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.1);
    background: white;
    z-index: 1;
    position: relative;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid white;

    &:not(:first-child) {
      margin-top: 10px;
    }

    .info {
      margin-right: 15px;
      display: block;

      b {
        font-weight: 600;
        font-size: 13px;
        color: $trabu-teal;
        line-height: 13px;
        display: block;
      }

      small {
        display: block;
        font-size: 11px;
        color: #999;
        margin-top: 3px;
      }
    }

    .code {
      font-size: 12px;
      display: flex;
      align-items: center;
      color: $trabu-teal;
      background: rgba(0, 0, 0, 0.05);
      padding: 3px 5px;
      flex-shrink: 0;
      border-radius: 5px;
      transition: 0.3s ease all;

      svg {
        margin-right: 3px;
      }
    }

    &.selected {
      border: 1px solid $trabu-orange;

      .code {
        background: $trabu-orange;
        color: white;
      }
    }
  }

  .airports-title {
    margin-top: 20px !important;
    display: block;
  }

  .airport-search {
    margin-top: 10px !important;
  }

  .companions {
    padding: 20px;
    background: #F4F4F4;
    position: relative;
    margin-top: 20px;
    border-radius: 10px;

    button {
      width: 100%;
      background: rgba(0, 0, 0, 0.02);
      color: $trabu-orange;
    }
  }

  .companion {
    &:not(:first-of-type) {
      margin-top: 10px;
    }

    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 20px;
    }

    position: relative;

    .delete {
      background: #FF4946;
      color: white;
      border: 0;
      width: 11px;
      height: 11px;
      padding: 0;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 32px;
      left: -16px;

      svg {
        width: 8px;
      }
    }
  }

  .field-group {
    display: flex;
    align-items: center;
    background: white;
    box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.1);
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;

    svg {
      flex-shrink: 0;
      width: 15px;
      margin-right: 5px;
    }

    input {
      flex-grow: 1;
      all: unset !important;
      font-size: 12px !important;
      color: #606060 !important;

      &[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
      }
    }
  }
}