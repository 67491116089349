@import '../../../../colors';

.btn-ideas-filter {
  position: fixed !important;
  z-index: 1;
  top: 165px;
  right: 30px;
  width: fit-content !important;
  float: right;
  padding: 5px 15px !important;
  height: 30px !important;

  svg {
    color: $trabu-orange;
    font-size: 16px !important;
  }
}

.ideas-page.notch .ideas-container .btn-ideas-filter {
  top: calc(env(safe-area-inset-top) + 165px - 20px);
}

.ideas-filters-header {
  .ideas-filters-header-toolbar {
    --background: white;
    --padding-start: 10px;
    --padding-end: 10px;
  }
}

.ideas-filters-content {
  flex-grow: 1;
  overflow-y: scroll;
  padding: 0 30px;
  padding-bottom: 30px;

  h3 {
    font-size: 14px;
    color: hsl(0, 0%, 10%);
    font-weight: 600;

    &:not(:first-child) {
      margin-top: 30px;
    }
  }

  h5 {
    color: #999999;
    margin: 0;
    font-size: 12px;
    margin-top: 5px;
    font-style: italic;
  }

  .slider-container {
    padding: 0 10px;
  }

  p {
    margin: 0;
    font-size: 14px;
    margin-top: -10px;
  }

  .MuiSlider-root {

    .MuiSlider-track {
      background: $trabu-orange !important;
      border: 0;
      height: 6px;
    }

    .MuiSlider-rail {
      height: 6px;
      background: #ddd !important;
    }

    .MuiSlider-thumb {
      background: $trabu-orange !important;

      &.Mui-active {
        box-shadow: 0px 0px 0px 8px rgba(0, 0, 0, 0.05);
      }
    }
  }
}