@import '../../colors';

.folder-masonry {
  width: -webkit-fill-available !important;
  width: fill-available !important;
}

.bucketlist-card {
  border-radius: 10px;
  box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.10);
  overflow: hidden;
  position: relative;
  transition: 0.3s ease all;
  width: 100%;
  aspect-ratio: 1;

  &.selected {
    padding: 15px;
  }

  .selecting-circle {
    &:not(.selecting) {
      opacity: 0;
    }

    position: absolute;
    top: 10px;
    left: 10px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid white;
    z-index: 2;
    color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease all;

    &.selected {
      border-color: $trabu-orange;
      background: $trabu-orange;
      color: white;
    }

    svg {
      width: 14px;
    }
  }

  .bucketlist-card-inner {
    position: relative;
    border-radius: 5px !important;
    overflow: hidden;
    background-size: cover;
    width: 100%;
    aspect-ratio: 1;

    .overlay {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.27) 0%, rgba(0, 0, 0, 0.00) 50%, rgba(0, 0, 0, 0.27) 100%);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    h3 {
      color: white;
      margin: 0;
      position: absolute;
      left: 10px;
      right: 10px;
      bottom: 10px;
      font-size: 14px;
      font-weight: 600;
    }

    img {
      display: block;
    }

    .icon {
      color: white;
      position: absolute;
      top: 10px;
      right: 10px;

      svg {
        width: 20px;
      }

      &.favourite {
        top: 38px;
      }
    }
  }
}

.bucketlist {
  padding: 30px;

  h1 {
    font-size: 22px;
    color: $trabu-teal;
    margin: 0;
  }

  .folder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.10);
    border-radius: 10px;
    background: white;
    padding: 12px 15px;
    margin-top: 15px;

    .folder-info {
      display: flex;
      align-items: center;

      svg {
        color: $trabu-orange;
        width: 22px;
        margin-right: 10px;
      }

      h3 {
        color: $trabu-teal;
        font-size: 12px;
        font-weight: 600;
        margin: 0;
      }
    }

    .folder-actions {
      button {
        display: flex;
        background: transparent;
      }

      svg {
        width: 22px;
        display: block;
        color: #999;
      }
    }
  }
}