@import '../../../colors';

.card{
  border-radius: 10px;
  overflow: hidden;
  position: relative;

  img{
    width: 100%;
    object-fit: cover;
    height: 100%;
    object-position: center;
    display: block;
  }

  .card-content{
    padding: 10px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    color: white;
    background: rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;

    h4{
      color: white;
      margin: 0;
      font-size: 14px;
      font-weight: 600;
      align-self: flex-start;
      padding: 5px 10px;
      background: linear-gradient(
                      180deg,
                      rgba(0, 0, 0, 0.15) 20%,
                      rgba(0, 0, 0, 0.15) 40%
      );
      opacity: 100%;
    }

    span.icon {
      align-self: flex-end;
      font-size: 20px;
    }
  }
}