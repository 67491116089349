.discover-image-container {
  position: relative;
}

.heart-button {
  position: absolute !important;
  bottom: 10px;
  right: 10px;
  z-index: 10;
  // background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  padding: 5px;
  min-width: 40px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
