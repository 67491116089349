@import '../../colors';

.gallery-header {
  position: relative;
  color: white;

  .type {
    position: absolute;
    top: 15px;
    left: 15px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;

    svg {
      margin-right: 5px;
    }
  }

  h3 {
    color: white;
    font-size: 24px;
    position: absolute;
    left: 15px;
    bottom: 15px;
    max-width: calc(100% - 90px);
    text-wrap: balance;
    font-weight: 600;
    line-height: 35px;
  }

  .heart-button {
    width: 50px;
    height: 50px;
    position: absolute;
    right: 15px;
    bottom: 15px;
    border: 0;
    background: white;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px !important;
    padding: 0 !important;
    color: $trabu-teal;
    transition: 0.3s ease all;

    &.saved {
      color: white;
      background: $trabu-orange;
    }
  }

  .sheet-gallery {
    .image-gallery-content:not(.fullscreen) {
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
      overflow: hidden;

      .image-gallery-slide {
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
          z-index: 3;
        }
      }

      &.image-gallery img {
        height: 250px;
        object-fit: cover;
      }

      img {
        height: 350px !important;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &.fullscreen-modal {
      z-index: 2000 !important;
      background: black !important;
      // backdrop-filter: blur(5px);
    }

    .image-gallery-bullets {
      bottom: 10px;

      button.image-gallery-bullet {
        box-shadow: none;
        opacity: 0.3;
        background-color: white;
        border-radius: 20px;
        transition: 0.3s ease all;

        &.active {
          opacity: 0.6;
          transform: none;
          width: 20px;
          transition: 0.3s ease all;
        }
      }
    }
  }

  .image-gallery-content.fullscreen {
    @media screen and (orientation: landscape) {
      .image-gallery-bullets {
        bottom: 40px !important;
      }
    }
  }

  .fullscreen-close-button {
    position: fixed;
    top: max(env(safe-area-inset-top), 20px);
    right: 20px;
    color: white;
    font-size: 30px;
    z-index: 2000;
  }
}
@media screen and (orientation: landscape) {
  .transform-component-module_content__FBWxo {
    margin: 0 30px 0 30px !important;
  }
}

@media screen and (orientation: portrait) {
  .gallery-v2-enabled .image-gallery .image-gallery-bullets {
    bottom: 100px !important;
  }
}

@media screen and (orientation: landscape) {
  .gallery-v2-enabled .image-gallery .image-gallery-bullets {
    bottom: 40px !important;
  }
}

.defaultImgStyle {
  display: flex !important;
  justify-content: center  !important;
  align-items: center  !important;
}
.defaultInnerImgStyle {
  width: auto  !important;
  height: auto  !important;
  max-width: 100%  !important;
  max-height: 100vh  !important;
  display: block  !important;
 
}
