@import "../../colors";

.trabu-preloader{
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: $trabu-orange;
  z-index: 100;

  svg{
    color: white;
    width: 40vw !important;
    height: 40vw !important;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;

    path{
      fill: white;
    }
  }
}