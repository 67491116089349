@import '../../colors';

.onboarding-view {
  position: relative;
  padding: 30px;
  padding-top: 90px;

  .trabu-icon {
    position: absolute;
    top: -100px;
    left: -100px;
    opacity: 0.03;
    z-index: -1;
  }

  h1 {
    color: $trabu-teal;
    margin: 0;

    span {
      display: block;
      color: $trabu-orange;
    }
  }

  input[type="text"], input[type="email"], input[type="password"] {
    all: unset;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border-bottom: 2px solid #ddd;
    color: $trabu-teal;
    transition: 0.3s ease all;
    margin-top: 20px;

    &:focus {
      border-bottom: 2px solid $trabu-orange;
    }

    &::placeholder {
      color: #999;
    }
  }

  .btn-back {
    position: absolute;
    top: 20px;
    left: 20px;
  }

  .validation {
    color: #D81E5B;
    font-size: 14px;
    margin-top: 10px;
    display: block;
  }
}