@import "../../colors";

.login-page{
  position: relative;

  .trabu-icon{
    width: 100vw;
    position: absolute;
    top: -60px;
    left: -60px;
    opacity: 0.03;
  }

  .login-form{
    padding: 30px;
    width: 100%;
    padding-top: 0;
    box-sizing: border-box;

    .btn-login{
      height: 40px !important;
    }

    h1{
      color: $trabu-teal;
      font-weight: 600;

      span{
        color: $trabu-orange;
      }
    }

    .form-label {
      display: block;
      margin-top: 10px;
      font-size: 14px;
      font-weight: 600;
      color: $trabu-teal;
      margin-bottom: 5px;
    }

    p {
      color: #606060;
      font-size: 14px;
    }
  }

  .login-footer{
    text-align: center;
    position: fixed;
    bottom: 0;
    width: calc(100vw - 60px);
    padding: 30px;

    .logo{
      height: 25px;
      margin-bottom: 10px;
    }
  }

  .btn-secondary-action{
    all: unset;
    margin: 0 auto !important;
    width: fit-content !important;
    display: block;
    font-family: "Poppins", sans-serif;
    letter-spacing: 0;
    text-transform: none;
    color: #606060;
    font-size: 12px;
    height: unset !important;
    font-weight: 500;

    span{
      color: $trabu-orange;
    }
  }
}