@import "../../colors";

.calendar-modal {
  h3 {
    color: $trabu-teal;
    font-size: 20px !important;
    margin-bottom: 20px;
  }

  .no-planned{
    background: rgba(0,0,0,0.07);
    border-radius: 10px;
    text-align: center;
    font-size: 12px;
    padding: 20px;
    color: #606060;
  }
}


.calendar-page {
  margin-bottom: 70px;

  .group-heading{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 40px !important;

    svg{
      font-size: 20px;
      margin-right: 10px;
      color: $trabu-orange;
    }

    h2{
      margin: 0;
      font-weight: 600;
      font-size: 18px;
      color: $trabu-teal;
    }

    &:not(:first-of-type){
      margin-top: 20px !important;
    }
  }
}

.calendar-circle-container{
  height: calc(100vh - 380px);
  display: flex;
}