.best-months, .best-ages {
  display: flex;

  &.best-months {
    .interval {
      width: calc((100% / 12) + 10px);
      text-transform: capitalize;

      &:last-child {
        width: calc(100% / 12);
      }
    }
  }

  &.best-ages {
    .interval {
      width: calc((100% / 7) + 10px);

      &:last-child {
        width: calc(100% / 7);
      }
    }
  }

  .interval {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 18px;
    flex-shrink: 0;
    font-size: 0.5rem;
    font-weight: 600;
    box-sizing: border-box;
    text-align: center;
    line-height: 18px;

    &:last-child {
      width: calc(100% / 12);
      border-radius: 10px;
    }

    &:not(:first-child) {
      margin-left: -10px;
    }

    &:not(:last-child) {
      padding-right: 10px;
    }

    &.best {
      background: #ABD8B2;
      color: #2A7436;
    }

    &.good {
      background: #BEF4D4;
      color: #196239;
    }

    &.mixed {
      background: #FAF8C7;
      color: #998040;
    }

    &.avoid {
      background: #FFC2C7;
      color: #D10414;
    }
  }
}

.legend {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;

  .item{
    &:before {
      content: '';
      width: 25px;
      height: 8px;
      border-radius: 20px;
      background: black;
      display: block;
      margin-bottom: 5px;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: 600;

    &.best {
      &:before {
        background: #ABD8B2;
      }
      color: #2A7436;
    }

    &.good {
      &:before {
        background: #BEF4D4;
      }
      color: #196239;
    }

    &.mixed {
      &:before {
        background: #FAF8C7;
      }
      color: #998040;
    }

    &.avoid {
      &:before {
        background: #FFC2C7;
      }
      color: #D10414;
    }
  }
}
