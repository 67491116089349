@import "../../colors";

// .profile {

  .profile-container {
    background: white;
    padding: 30px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      display: none;
    }

    h1 {
      color: $trabu-teal;
      font-size: 24px;
      margin: 0;
    }
  }

  .link {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.05);
    margin-top: 15px;
    text-decoration: none;
    color: $trabu-dark-teal;
    font-size: 13px;
    border: 0;
    width: 100%;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    position: relative;
    text-align: left;

    svg {
      flex-shrink: 0;
      color: $trabu-orange;
      margin-right: 10px;
      font-size: 20px;
    }
  }

  .option {
    font-family: "Poppins", sans-serif;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-radius: 5px;
    box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.10);
    text-decoration: none;
    margin-top: 15px;
    position: relative;
    border: 0;
    width: 100%;
    box-sizing: border-box;
    background: white;

    &:disabled {
      opacity: 0.5;
    }

    svg {
      flex-shrink: 0;
      color: $trabu-orange;
      margin-right: 10px;
      font-size: 20px;
      width: 20px;
    }

    .option-text {
      color: #999;
      font-size: 12px;
      font-weight: 400;
      flex-grow: 1;
      text-align: left;

      span {
        display: block;
        font-weight: 600;
        color: $trabu-teal;
        margin-bottom: 5px;
      }
    }
  }
// }