@import '../../colors';

.airports {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: -20px;
  height: calc(100vh - 155px);
  padding: 30px;
  box-sizing: border-box;
  background-image: url("../../../public/transparent-icon.png");
  background-size: 120%;
  background-repeat: no-repeat;
  background-position: -100px -100px;
  background-color: white;
  overflow-y: scroll;

  small {
    font-size: 12px;
    color: #606060;
    font-style: italic;
  }

  .selected-airport {
    padding: 10px;
    box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.10);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    margin-bottom: 15px;

    .airport-details {
      font-size: 10px;
      color: #606060;

      .airport-name {
        font-weight: 600;
        color: $trabu-teal;
        font-size: 12px;
        display: block;
        margin-bottom: 5px;

        .airport-code {
          color: white;
          font-size: 9px;
          background: $trabu-orange;
          padding: 2px 7px;
          border-radius: 4px;
          font-weight: 600;
          width: fit-content;
          margin-right: 5px;
        }
      }
    }

    .delete-airport {
      background: $red;
      min-width: unset;
      width: 28px;
      height: 28px;
      color: white;
      padding: 0;
    }
  }

  .search-results {
    .airport {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      background: #ECECEC;
      border-radius: 5px;
      margin-top: 15px;
      border: 1px solid #ECECEC;
      transition: 0.3s ease all;

      .info {
        font-size: 10px;
        color: #606060;

        span {
          font-weight: 600;
          color: $trabu-teal;
          font-size: 12px;
          display: block;
        }
      }

      .code {
        font-size: 12px;
        padding: 2px 7px;
        border-radius: 4px;
        font-weight: 600;
        width: fit-content;
        margin-right: 5px;
        flex-shrink: 0;
        color: $trabu-orange;
        background: white;
        transition: 0.3s ease all;
      }

      &.selected {
        border: 1px solid $trabu-orange;

        .code {
          color: white;
          background: $trabu-orange;
        }
      }
    }
  }
}