@import '../../colors';

.trip-schedule-modal{
  .block{
    padding: 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.10);
    font-size: 14px;
    margin-top: 10px;
  }

  .holidays{
    max-height: 400px;
    overflow-y: scroll;
    margin-top: 10px;

    .holiday{
      padding: 10px;
      background: #F4F4F4;
      border-radius: 5px;
      margin-top: 10px;
      font-size: 14px;
      position: relative;

      &:after{
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        background: $trabu-orange;
        border-radius: 100px;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        opacity: 0.2;
        transition: 0.3s ease all;
      }

      &:before{
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background: white;
        border-radius: 100px;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        opacity: 0;
        transition: 0.3s ease all;
      }

      &.selected:before, &.selected:after{
        opacity: 1;
      }
    }
  }
}