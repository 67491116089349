@import '../../../colors';

@keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }

  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }

  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}

.trip-fake-card {
  .trip-card-image {
    border-radius: 10px;
    position: relative;
    animation: pulse 1s infinite ease-in-out;
  }

  .placeholder-title {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 70%;
    height: 10px;
    border-radius: 40px;
    animation: pulse 1s infinite ease-in-out;
    animation-delay: 0.3s;
  }
}