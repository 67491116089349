@import '../../colors.scss';

.terms {
    padding: 1rem 2rem;

    &-title {
        font-size: 22px;
        font-weight: 500;
        line-height: 1.5;
        margin-bottom: 1rem;
        color: #000000;
    }

    &-content {
        font-size: 16px;
        line-height: 1.5;
    }

    &-link {
        color: $trabu-orange;
    }

    &-checkbox {
        display: flex;
        align-items: stretch;
        gap: 8px;
        font-size: 14px;
        margin-bottom: 10px;
    }
}