@media screen and (orientation: landscape) {
  .upperLayout {
    height: 100vh !important;
    width: 100vw !important;
    overflow: hidden !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

@media screen and (orientation: portrait) {
    .upperLayout {
        height: 80vh !important;
        width: 100vw !important;
        overflow: hidden !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }
  }
