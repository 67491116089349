@import "../../colors";

.selectable-pill{
  font-family: "Poppins", sans-serif;
  height: 30px;
  padding: 0 20px;
  border: 1px solid #dadada;
  font-size: 12px;
  color: #999;
  background: transparent;
  margin-top: 10px;
  margin-right: 10px;
  border-radius: 20px;
  transition: 0.3s ease all;
  line-height: 27px;

  &:hover{
    background: transparent;
  }

  &.selected{
    background: $trabu-teal;
    color: white;
    border-color: $trabu-teal;
  }

  &.pill-clear{
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 500;
    margin-top: 0;
    text-transform: none;
    letter-spacing: 0;
    padding: 10px;
    background: #D81E5B !important;
    color: white;
    border: 1px solid #D81E5B;
    border-radius: 50px;
  }

  svg {
    width: 16px;
    vertical-align: text-bottom;
    height: 16px;
    margin-right: 5px;
  }
}