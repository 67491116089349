@import "../../colors";

.signup-page {
  .trabu-icon {
    width: 100vw;
    position: fixed;
    top: -60px;
    left: -60px;
    opacity: 0.03;
    z-index: -1;
  }

  .signup-form{
    padding: 0 30px 20px 30px;

    label{
      color: $trabu-teal;
      font-size: 12px;
      font-weight: 500;
      display: block;
      margin-top: 20px;
      margin-bottom: 5px;
    }

    input[type="checkbox"]{
      display: none;
    }

    input[type="checkbox"] {
      & + label.terms-field {
        padding-left: 35px;
        position: relative;
        color: #606060;
        font-weight: 400;
        margin-top: 10px;

        &:before {
          content: '✔';
          text-align: center;
          line-height: 20px;
          background: #E0E2E8;
          border-radius: 5px;
          width: 20px;
          height: 20px;
          position: absolute;
          top: 0;
          left: 0;
          color: #E0E2E8;
          transition: 0.2s ease all;
        }

        a {
          text-decoration: none;
          color: $trabu-orange;
          font-weight: 500;
        }
      }

      &:checked + label.terms-field{
        &:before{
          color: white;
          background: $trabu-orange;
        }
      }
    }
  }

  h1{
    color: $trabu-teal;
    font-weight: 600;
    margin: 0;

    span{
      color: $trabu-orange;
    }
  }
  .btn-secondary-action{
    all: unset;
    margin: 0 auto !important;
    width: fit-content !important;
    display: block;
    font-family: "Poppins", sans-serif;
    letter-spacing: 0;
    text-transform: none;
    color: #606060;
    font-size: 12px;
    height: unset !important;
    font-weight: 500;

    span{
      color: $trabu-orange;
    }
  }
}

.signup-progress{
  //position: absolute;
  //bottom: 0;
  //left: 0;
  width: 100%;
}

.signup-progress{
  .MuiLinearProgress-root{
    height: 10px;
    background: rgba(0,0,0,0.15);

    .MuiLinearProgress-bar{
      background: $trabu-orange;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}