@import '../../colors';

.support-content {
  padding: 30px;

  .MuiAccordion-root {
    margin-bottom: 20px;
    box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.10);

    &:before {
      display: none;
    }

    .MuiAccordionSummary-content {
      font-weight: 600;
      font-size: 14px;
      color: $trabu-teal;
      margin: 15px 0 !important;
    }

    .MuiAccordionSummary-root {
      min-height: unset;
    }

    .MuiAccordionDetails-root {
      color: #606060;
      font-size: 14px;
    }

    .MuiAccordionSummary-expandIconWrapper {
      color: $trabu-orange;
    }
  }
}