@import '../../colors';

.experience-info-notch {
  height: env(safe-area-inset-top, 0);
  background: white;
  border-bottom: 1px solid #E5E5E5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 3;
}

.experience-view {
  padding-bottom: 60px;
}