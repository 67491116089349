@import '../../colors';

.schedule-trip-dialog {
  .schedule-trip {
    display: table;
    align-items: center;
    box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.1);
    background: white;
    border-radius: 5px;
    overflow: hidden;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .schedule-trip-image {
      background-size: cover;
      display: table-cell;
      width: 50px;
    }

    .schedule-trip-name {
      margin-left: 10px;
      font-size: 14px;
      font-weight: 600;
      color: $trabu-teal;
      padding: 10px;
    }
  }
}

.holiday-node {
  display: flex;
  justify-content: space-between;
  margin-bottom: 31px;

  .holiday-info {
    width: calc((100% - 120px) / 2);
    box-sizing: border-box;
  }

  .holiday-name {
    color: $trabu-teal;
    font-weight: 600;
    font-size: 12px;
    text-align: right;
    padding-right: 20px;
    padding-left: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    order: 1;
  }

  .trip-title {
    color: #999;
    font-weight: 300;
    font-size: 12px;
    font-style: italic;
    text-align: left;
    display: flex;
    align-items: center;
    order: 3;
  }


  .holiday-circle {
    height: 90px;
    width: calc(100% - (100% - 90px) / 2);
    border-radius: 45px 0 0 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 22px;
    order: 2 !important;
    background-color: #eeeeee;
    background-size: cover;
    background-position: center;

    .holiday-icon {
      color: #000000;
      opacity: 0.4;
      flex-shrink: 0;
      transform: translate(calc(45px - 50%));

      img {
        display: block;
        filter: grayscale(100%) brightness(0);
      }
    }

    svg {
      display: block;
    }

    .semicircle {
      height: 130px;
      position: absolute;
      top: -20px;
      left: -20px;
    }

    .holiday-button-holder {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      padding-left: 30px;

      .btn {
        height: 40px;
        width: 40px !important;
      }
    }
  }

  &.odd {
    .semicircle {
      transform: rotate(180deg);
      left: unset;
      right: -20px;
    }

    .holiday-name {
      order: 3;
      text-align: left;
      padding-left: 20px;
      padding-right: 30px;
      justify-content: start;
    }

    .holiday-icon {
      transform: translate(calc(-1 * calc(45px - 50%)));
    }

    .holiday-circle {
      border-radius: 0 45px 45px 0;
    }

    .holiday-button-holder {
      order: -1;
      padding-left: 0;
      padding-right: 30px;
    }

    .trip-title {
      order: 1;
      text-align: right;
      justify-content: end;
    }
  }

  &.planned {
    .trip-title {
      color: #606060;
      font-style: normal;
      font-weight: 400;
    }

    .holiday-circle {
      color: white;
      width: calc(100% - (100% - 90px) / 2);
      border-radius: 45px 0 0 45px;

      .holiday-icon {
        width: 90px;
        opacity: 1;

        svg, img {
          margin: 0 auto;
          color: white !important;
        }

        img {
          display: block;
          filter: grayscale(100%) brightness(0) invert(1);
        }
      }

      .holiday-trip-title {
        flex-grow: 1;
        text-align: right;
        font-weight: 600;
        font-size: 13px;
        padding-right: 30px;
      }
    }
  }

  &.odd.planned {
    .holiday-circle {
      border-radius: 0 45px 45px 0;

      .holiday-trip-title {
        text-align: left;
        padding-left: 30px;
        padding-right: 0;
        order: 1;
      }

      .holiday-icon {
        order: 2;
      }
    }
  }
}

.timeline-node-modal {
  .trip-placeholder {
    background: #eee;
    font-size: 12px;
    font-style: italic;
    color: #606060;
    padding: 20px;
    border-radius: 10px !important;
    text-align: center;
  }

  .trip-image {
    height: 150px;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
  }

  h4 {
    font-weight: 600;
    margin: 15px 0;
    padding: 0;
    font-size: 14px;
    color: #1f1f1f;
  }
}