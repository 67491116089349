@import "../../colors";

.trabu-toolbar{
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 0;
    width: 100vw;
    box-sizing: border-box;
    display: flex;
    box-shadow: -10px -10px 15px 0px rgba(0, 0, 0, 0.05);
    background: white;

    .toolbar-icon{
        font-size: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        color: #1a1a1a;
        font-weight: 500;
        margin-bottom: 20px;
        background: transparent;
        border: none;
        font-family: "Poppins", sans-serif;
        flex-grow: 1;
        padding: 15px 0;

        svg{
            font-size: 20px;
            margin-bottom: 2px;
            color: $trabu-teal;
        }

        &.active{
            svg {
                color: $trabu-orange;
            }
        }
    }
}