@import '../../colors';

.trabu-app-container.calendar {
  padding: 0;
  padding-top: 50px;
  padding-bottom: 70px;

  .year-separator {
    border-top: 2px dashed #eee;
    color: $trabu-orange;
    font-size: 14px;
    font-weight: 700;
    margin: -19px 30px 7px;

    span {
      display: block;
      margin-top: -11px;
      background: white;
      width: fit-content;
      padding-right: 5px;
    }
  }
}

.title {
  color: $trabu-teal;
}

.calendar-year-selector {
  background: #f4f4f4;
  padding: 5px;
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 20px;
  left: 30px;
  right: 30px;
  z-index: 20;
  box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.1);

  span {
    font-size: 14px;
    color: #606060;
    font-weight: 600;
  }

  .btn {
    width: 35px !important;
    height: 35px !important;
    min-width: unset;
  }
}
.calenderlist {
  padding: 5px 23px;
}
