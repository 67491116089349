@import '../../colors';

.calenderheader-card {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  transition: 0.3s ease all;
  width: 100%;
  background-size: cover;
  height: 100px;

  .overlay {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.27) 0%,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 0.27) 100%
    );
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .btnstyle {
    width: auto;
    border-radius: 30px !important;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 600 !important;
    text-transform: none !important;
    transition: 0.2s ease all !important;
    letter-spacing: 0 !important;
    padding: 6px 12px;
    font-size: 10px;
    position: absolute;
    right: 15px;
    top: 15px;
  }
}

.footertext {
  position: absolute;
  bottom: 10px;
  left: 20px;

  h5 {
    color: white;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins';
  }
}
