@import '../../colors';
@import '~react-image-gallery/styles/scss/image-gallery.scss';

// Wrapper to center the button
.button-container {
  display: flex;
  justify-content: center;
  width: 50%;
  margin: 0 auto;
  margin-bottom: 20px;
}

.trip-info-notch {
  height: env(safe-area-inset-top, 0);
  background: white;
  border-bottom: 1px solid #e5e5e5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 3;
}

.trip-info-header {
  background-size: cover !important;
  background-position: center !important;
  height: 350px;
  position: relative;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  overflow: hidden;

  .fullscreen-modal {
    z-index: 2000 !important;
    background: black !important;
  }

  .fullscreen-close-button {
    position: fixed;
    top: max(env(safe-area-inset-top), 20px);
    right: 20px;
    color: white;
    font-size: 30px;
    z-index: 2000;
  }

  .image-gallery-content.fullscreen {
    @media screen and (orientation: landscape) {
      .image-gallery-bullets {
        bottom: 40px !important;
      }
    }

    button.image-gallery-bullet {
      box-shadow: none;
      opacity: 0.3;
      background-color: white;
      border-radius: 20px;
      transition: 0.3s ease all;

      &.active {
        opacity: 0.6;
        transform: none;
        width: 20px;
        transition: 0.3s ease all;
      }
    }

    top: 50%;
    transform: translateY(-50%);
  }

  .image-gallery-content:not(.fullscreen) {
    img {
      height: 350px !important;
      width: 100%;
      background-size: cover;
      background-position: center;
    }

    .trip-header-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(201, 24, 24, 0.4);
      z-index: 4;
    }

    .image-gallery-slide {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
        z-index: 3;
      }
    }

    img {
      height: 250px;
      object-fit: cover;
    }

    .image-gallery-bullets {
      bottom: 10px;

      button.image-gallery-bullet {
        box-shadow: none;
        opacity: 0.3;
        background-color: white;
        border-radius: 20px;
        transition: 0.3s ease all;

        &.active {
          opacity: 0.6;
          transform: none;
          width: 20px;
          transition: 0.3s ease all;
        }
      }
    }
  }

  span.type {
    position: absolute;
    top: max(env(safe-area-inset-top), 15px);
    right: 15px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    color: white;

    svg {
      margin-right: 5px;
    }
  }

  button.back,
  button.share {
    color: white;
    padding: 0;
    background: none;
    border: none;
    position: absolute;
    top: max(env(safe-area-inset-top), 15px);
    left: 15px;
    font-size: 24px;

    &.share {
      left: 50px;
      font-size: 22px;
    }
  }

  .btn-love {
    position: absolute;
    bottom: 15px;
    right: 15px;
    font-size: 22px !important;
    padding: 0 !important;

    svg {
      width: unset;
    }
  }

  h1 {
    color: white;
    font-size: 24px;
    position: absolute;
    left: 15px;
    bottom: 15px;
    max-width: calc(100% - 90px);
    text-wrap: balance;
    font-weight: 600;
    line-height: 35px;
  }
}

.trip-information {
  position: relative;
  z-index: 2;
  padding-bottom: 30px;

  .itinerary-controls {
    padding: 0 30px 20px;

    span {
      display: block;
      font-size: 14px;
      color: $trabu-teal;
      margin-top: 20px;

      svg {
        color: $trabu-orange;
        vertical-align: middle;
      }

      b {
        font-weight: 600;
      }
    }

    h2 {
      color: #163d4a;
      font-size: 18px;
      font-weight: 600;
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }

  .itinerary-buttons {
    display: flex;
    gap: 10px;
    position: fixed;
    bottom: 20px;
    left: 30px;
    right: 30px;
    z-index: 3;

    button {
      height: 45px !important;
    }

    transition: 0.5s ease all;

    &.hidden {
      visibility: hidden;
      opacity: 0;
      transform: translateY(50px);
    }
  }

  h3 {
    color: $trabu-teal;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .schedule {
    padding: 20px;
    border-radius: 10px;
    background: #f4f4f4;
    font-size: 14px;
    margin-top: 15px;
    display: block;
  }

  .scheduled-trip {
    background: rgba(0, 0, 0, 0.05);
    padding: 20px;
    border-radius: 10px;
    text-align: center;

    p {
      margin-top: 0;
      color: #606060;
      font-size: 14px;
      text-align: left;

      b {
        font-weight: 600;
      }
    }

    .MuiCircularProgress-root {
      svg {
        color: $trabu-orange;
      }
    }
  }

  h2 {
    color: $trabu-teal;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .pace-selector {
    position: relative;

    .pace-selector-levels {
      button {
        position: absolute;
        z-index: 3;
        visibility: hidden;
        opacity: 0;
        right: 0;

        &:first-child {
          top: 60px;
          transition-delay: 0.2s !important;

          &.visible {
            transition-delay: 0s !important;
          }
        }

        &:nth-child(2) {
          top: 120px;
          transition-delay: 0.1s !important;
        }

        &:last-child {
          top: 180px;
          transition-delay: 0s !important;

          &.visible {
            transition-delay: 0.2s !important;
          }
        }

        &.visible {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  .daily-itinerary {
    h3 {
      color: $trabu-teal;
      font-size: 16px;
    }
  }

  .trip-description {
    font-size: 14px;
    color: #606060;
    margin-top: 0;

    p:first-child {
      margin-top: 0;
    }
  }

  .additional-image {
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
    object-position: center;
    border-radius: 5px;

    &.large {
      border-radius: 10px;
    }
  }

  .trip-buttons {
    button {
      margin-right: 15px;
    }

    margin-bottom: 20px;
  }

  .trip-property {
    font-size: 14px;
    display: block;
    color: $trabu-teal;
    margin-bottom: 10px;

    svg {
      color: $trabu-orange;
      margin-right: 5px;
      line-height: 16px;
      vertical-align: middle;
      margin-top: -3px;
    }

    b {
      font-weight: 600;
      margin-left: 5px;
    }
  }

  .headline {
    display: flex;
    align-items: center;

    svg {
      color: $trabu-orange;
      font-size: 20px;
      margin-right: 5px;
    }

    h2 {
      font-size: 18px;
      font-weight: 600;
      margin: 0;
    }
  }

  .headline-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 20px;

    .headline-actions {
      font-size: 20px;
      color: $trabu-orange;

      svg {
        display: block;
      }

      .MuiTooltip-popper {
        max-width: 300px;
      }
    }
  }

  h3.subtitle {
    margin: 0;
    font-size: 13px;
    font-weight: 500;
    font-style: italic;
    color: #606060;
    margin-bottom: 10px;
  }

  .itinerary-length-adjust {
    position: relative;
    flex-grow: 1;

    .itinerary-length-box {
      box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      background: white;
      position: absolute;
      top: 70px;
      left: 0;
      width: calc(100vw - 60px);
      padding: 20px;
      box-sizing: border-box;
      text-align: center;
      color: #606060;

      opacity: 0;
      visibility: hidden;
      transition: 0.3s ease all;

      &.visible {
        opacity: 1;
        visibility: visible;
      }

      .MuiSlider-track {
        background-color: $trabu-orange;
        border-color: $trabu-orange;
      }

      .MuiSlider-thumb {
        background-color: $trabu-orange;
      }

      .MuiSlider-rail {
        background-color: rgba(0, 0, 0, 0.3);
      }

      button {
        width: 40px !important;
        height: 40px;
        background: $trabu-teal !important;
      }

      .itinerary-btn {
        width: 100% !important;
      }
    }
  }
}

.trip-information-page {
  padding: 20px;
  background: white;
  height: calc(100vh - 80px);
  border-radius: 20px;
  box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.15);
  overflow-y: auto;

  h2 {
    color: $trabu-teal;
    font-weight: 700;
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
    color: $trabu-teal;
    margin-bottom: 10px;
  }

  h3 {
    color: $trabu-orange;
    margin-top: 20px;
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      background: #f8f8f8;
      margin-bottom: 10px;
      padding: 10px;
      border-radius: 10px;
      box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    }
  }

  .back-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: $trabu-orange;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 600;
  }
}
.overviewlist {
  padding-right: 30px;
  padding-bottom: 30px;
  padding-left: 30px;
}
.image-gallery.fullscreen-modal {
  height: 100vh !important;
  width: 100vw !important;
}
@media screen and (orientation: landscape) {
  .transform-component-module_content__FBWxo {
    margin: 0 30px 0 30px !important;
  }
}
@media screen and (orientation: portrait) {
  .gallery-v2-enabled .image-gallery .image-gallery-bullets {
    bottom: 100px !important;
  }
}

@media screen and (orientation: landscape) {
  .gallery-v2-enabled .image-gallery .image-gallery-bullets {
    bottom: 40px !important;
  }
}

.defaultImgStyle {
  display: flex !important;
  justify-content: center  !important;
  align-items: center  !important;
}
.defaultInnerImgStyle {
  width: auto  !important;
  height: auto  !important;
  max-width: 100%  !important;
  max-height: 100vh  !important;
  display: block  !important;
 
}

