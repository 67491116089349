@import '../../colors';


.scheduled-item {
  margin-top: 20px;
  background: rgba(0, 0, 0, 0.05);
  padding: 20px;
  border-radius: 10px;
  text-align: center;

  p {
    margin-top: 0;
    color: #606060;
    font-size: 14px;
    text-align: left;

    b {
      font-weight: 600;
    }
  }

  .MuiCircularProgress-root {
    svg {
      color: $trabu-orange;
    }
  }
}
.btn-margin {
  margin-top: 20px !important;
}
.loading-container .MuiCircularProgress-root svg {
  color: white;
}
