@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&family=Recursive:wght@300;400;700&display=swap');
@import url('react-notifications/lib/notifications.css');
@import "trabu";


:root {
  --trabu-teal: #163D4A;
  --darkblue: #1136A8;
  --lightblue: #1E5EDC;
  --grey: #4B4B4B;
  --lightgrey: #606060;
  --lighterblue: #00d5ff;
  --ion-font-family: 'Poppins', sans-serif;
  --ion-color-primary: #EB7E45;
  --ion-color-primary-rgb: 235,126,69;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0,0,0;
  --ion-color-primary-shade: #cf6f3d;
  --ion-color-primary-tint: #ed8b58;
}

ion-tab-button {
  --color: #163D4A;
  --color-selected: #EB7E45;
}

ion-tab-bar {
  --background: white;
  --border: none;
  box-shadow: -10px -10px 15px 0px rgba(0, 0, 0, 0.05);
}

.header-wrapper {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.25);
}

.header {
  --background: #163D4A;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  .header-inner {
    padding-left: 26px;
    padding-right: 26px;
    display: flex;
    padding-bottom: 20px;

    svg {
      color: $trabu-orange;
      width: 28px;
      height: 28px;
      margin-right: 7px;
    }

    h1 {
      color: white;
      margin: 0;
    }
  }
}

.content {
  padding: 30px;
}

*:focus {
  outline: none;
}

body {
  background: white;
  background-size: cover;
  min-height: calc(100 * var(--vh));
  background-position: center;
  font-family: "Poppins", sans-serif !important;
  position: relative;
  overscroll-behavior-y: none;
}

.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: calc(100 * var(--vh));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1 {
  color: var(--darkblue);
  text-align: left;
  font-size: 30px;
  font-weight: 600 !important;
}

h3 {
  color: var(--darkblue);
  text-align: left;
  font-size: 22px;
  font-weight: 600;
  margin: 0;
}

h4 {
  color: var(--darkblue);
  font-size: 20px;
  font-weight: 600;
}

h5 {
  font-weight: 600;
  font-size: 17px;
  color: var(--grey);
  margin: 0;
  margin-bottom: 10px;
  text-align: left;
}

input[type="radio"] {
  display: none;
}

input[type="radio"] + label {
  display: block;
  text-align: left;
  padding-left: 35px;
  position: relative;
  margin-bottom: 10px;
  font-size: 16px;
  color: #4B4B4B;
  line-height: 25px;
}

input[type="radio"] + label:before {
  width: 25px;
  content: '';
  height: 25px;
  border-radius: 30px;
  background: white;
  position: absolute;
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.1);
  left: 0;
}

input[type="radio"]:checked + label {
  font-weight: 500;
}

input[type="radio"]:checked + label:after {
  width: 15px;
  height: 15px;
  background: var(--darkblue);
  border-radius: 20px;
  content: '';
  position: absolute;
  left: 5px;
  top: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea {
  width: 100%;
  border: 0;
  border-radius: 10px;
  background: white;
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.1);
  font-family: "Poppins", sans-serif;
  padding: 20px;
  font-size: 15px;
  width: -webkit-fill-available;
}

input[type="text"], input[type="email"], input[type="date"], input[type="password"] {
  width: 100%;
  border: 0;
  border-radius: 10px;
  background: white;
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.1);
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  padding: 14px 20px;
  width: -webkit-fill-available;
}

::placeholder {
  font-style: italic;
  color: var(--grey);
}

//.btn{
//  font-family: "Poppins", sans-serif !important;
//  font-weight: 500 !important;
//  text-transform: none !important;
//  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.1) !important;
//  transition: 0.2s ease all !important;
//  letter-spacing: 0 !important;
//}

.btnLarge {
  padding: 13px 25px !important;
  font-size: 16px !important;
  line-height: 19px !important;
}

.btnSmall {
  font-size: 14px !important;
  line-height: 17px !important;
  padding: 11px 20px !important;
}

.btnGradient {
  background: linear-gradient(267.04deg, var(--darkblue) 0%, var(--lightblue) 100%);
  color: white !important;
}

.btnGrey {
  background: #E9E9E9 !important;
  color: var(--darkblue) !important;
}

.btnWhite {
  background: white !important;
  color: var(--darkblue) !important;
}

.btnRed {
  background: linear-gradient(to right, #D31027, #EA384D) !important;
  color: white !important;
}

.customSwitch {
  position: relative;
  padding-left: 55px;
  height: 25px;
  line-height: 25px;
  font-size: 15px;
  color: var(--lightgrey);
  cursor: pointer;
}

.notification-container {
  width: unset;
  overflow: visible;
}

.notification-container .notification {
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.notification-container h4 {
  color: white !important;
  font-size: 16px !important;
}

.notification-container .message {
  font-size: 14px !important;
}

ion-header.transparent-header-wrapper {
  box-shadow: none !important;
}

.transparent-header {
  --background: transparent;
  --border-width: 0 !important;
  --color: white;
  transition: 0.3s ease all;
  --padding-start: 15px;
  --padding-end: 15px;

  ion-buttons {
    ion-button {
      svg {
        color: white;
      }
    }
  }

  &.scrolled {
    color: $trabu-teal;
    --background: white;
    box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.25);

    ion-buttons ion-button svg {
      color: $trabu-teal;
    }
  }
}

.transparent-header-icon {
  --padding-start: 0 !important;
  --padding-end: 0 !important;
  margin: 0 !important;

  &.back {
    margin-right: 10px !important;
  }

  svg {
    width: 24px;
  }
}

.transparent-header-type {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;

  svg {
    width: 16px;
    margin-right: 5px;
  }
}

.transparent-header-content {
  --padding-top: 0;
}

.transparent-header .toolbar-container {
  display: none;
}

.splash-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
}