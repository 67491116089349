@import '../../../colors';

.itinerary {
  margin-bottom: 40px;
}

.itinerary-block {
  padding-left: 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;

  .itinerary-block-node {
    width: 15px;
    height: 15px;
    background: $trabu-orange;
    border-radius: 50%;
    z-index: 2;
    flex-shrink: 0;
  }

  .itinerary-block-content {
    height: 85px;
    margin-left: 20px;
    width: 100%;
    margin-top: 3px;
    margin-bottom: 3px;
    position: relative;
    background-position: center;
    background-size: cover;
    color: white;
    padding-left: 15px;
    display: flex;
    align-items: center;

    svg {
      font-size: 20px;
      flex-shrink: 0;
    }

    h2 {
      margin: 0;
      font-size: 14px;
      text-wrap: balance;
      margin-left: 15px;
      font-weight: 600;
      flex-grow: 1;
      color: white;
    }

    &:before {
      height: calc(50% + 3px);
      width: 5px;
      content: '';
      background: $trabu-orange;
      display: block;
      position: absolute;
      top: -3px;
      left: -30px;
      z-index: 1;
    }

    &:after {
      height: calc(50% + 3px);
      width: 5px;
      content: '';
      background: $trabu-orange;
      display: block;
      position: absolute;
      top: 50%;
      left: -30px;
      z-index: 1;
    }
  }

  &.destination {
    .itinerary-block-content {
      border-top-left-radius: 40px;

      &:before {
        background: #D9D9D9;
      }
    }
  }

  &.last-item {
    .itinerary-block-content {
      border-bottom-left-radius: 40px;

      &:after {
        background: #D9D9D9;
      }
    }
  }
}

.itinerary-travel-block {
  display: flex;
  padding-left: 30px;
  align-items: center;

  .itinerary-block-node {
    width: 15px;
    height: 15px;
    background: white;
    border: 4px solid $trabu-orange;
    border-radius: 50%;
    z-index: 2;
    flex-shrink: 0;
  }

  .itinerary-travel-content {
    padding: 15px 0;
    margin-left: 20px;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    flex-grow: 1;
    line-height: 20px;
    display: flex;
    align-items: center;

    svg {
      font-size: 18px;
      //vertical-align: text-top;
      margin-right: 10px;
      flex-shrink: 0;
    }

    span {
      font-weight: 400;
    }

    &:before {
      height: 50%;
      width: 5px;
      content: '';
      background: #D9D9D9;
      display: block;
      position: absolute;
      top: -3px;
      left: -30px;
      z-index: 1;
    }

    &:after {
      height: 50%;
      width: 5px;
      content: '';
      background: #D9D9D9;
      display: block;
      position: absolute;
      top: 50%;
      left: -30px;
      z-index: 1;
    }
  }

  &.first .itinerary-travel-content:before {
    background: transparent;
  }

  &.last .itinerary-travel-content:after {
    background: transparent;
  }
}