@import '../../colors.scss';

.tabs-3 {
  .tab-bar {
    width: 100%;
    background: #F4F4F4;
    border-radius: 50px;
    padding: 5px;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 20px;

    button {
      width: 33.33%;
      border-radius: 50px;
      font-size: 14px;
      font-weight: 600;
      color: #606060;
      padding: 10px 0;
      transition: 0.3s ease all;
      background: transparent;
      position: relative;
      z-index: 1;

      &.active {
        color: $trabu-teal;
      }

      &:disabled {
        opacity: 0.2;
      }
    }

    &:after {
      content: '';
      width: 33.33%;
      top: 5px;
      bottom: 5px;
      background: white;
      box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.10);
      border-radius: 50px;
      position: absolute;
      transition: 0.3s ease all;
    }

    &.tab-2 {
      &:after {
        transform: translateX(calc(100% - 5px));
      }
    }

    &.tab-3 {
      &:after {
        transform: translateX(calc(200% - 10px));
      }
    }
  }

  .placeholder {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
    font-style: italic;
    color: #999;
  }
}