.titletext {
  color: white;

  align-self: center;
  margin-left: 10px;

  font-size: 13px;
  font-weight: 600;
  background: unset;
  padding: 4px;
}
.header-card {
  display: flex;
  color: white;
  position: absolute;
  justify-content: space-between;
  align-items: center;
  width: '100%';
  height: auto;
  // height: '-webkit-fill-available';
  padding: 0 10px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
}
.header-left {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.header-right {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: white;
}
.expand-less {
  align-self: center;
  transform: rotate(180deg);
  color: black;
  width: 25px;
  height: 25px;
}
.expand-more {
  align-self: center;
  transform: rotate(270deg);
  transition: transform 0.3s ease;
  color: black;
  width: 25px;
  height: 25px;
}

.MuiAccordion-root {
  box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.1);

  &:before {
    display: none;
  }
}

.accordion {
  box-shadow: none !important;
  margin: 0 !important;
  background-color: transparent !important;
  padding: 0;
}
.accordion-summary {
  display: flex !important;
  padding: 0 !important;
  margin: 5px 0 !important;
  border: none !important;
  background-color: transparent !important;
}
.accordion-details {
  padding: 0 !important;

  margin: 5px 15px !important;
}

.MuiAccordionSummary-contentGutters {
  margin: 0px !important;
  padding: 0px !important;
}
