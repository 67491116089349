@import '../../colors';

.orange-spinner {
  color: $trabu-orange;
}

.folder-search-row {
  .selecting-count {
    box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.10);
    background: white !important;
    color: $trabu-teal;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 15px;
    border-radius: 10px;
    font-size: 18px;
    line-height: 18px;

    svg {
      color: $trabu-orange;
    }
  }

  .search-input {
    border: 0 !important;
    box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.10);
    border-radius: 10px;
    background: white;

    input {
      background: transparent;
      box-shadow: none;
      padding-left: 5px;
    }

    svg {
      width: 22px;
      color: $trabu-orange;
    }

    fieldset {
      display: none;
    }
  }
}

.folder-card {
  box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.10);
  border-radius: 10px;
  background: white;
  width: 100%;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-size: 15px;
  color: #606060;

  svg {
    color: $trabu-orange;
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
  }
}

.folder-floater {
  position: fixed;
  bottom: 20px;
  left: 40px;
  right: 40px;
  border-radius: 100px;
  transition: 0.2s ease all;
  opacity: 1;
  visibility: visible;
  z-index: 2;

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }

  &.info {
    border: 0.5px solid #EB7E45;
    background: #FCE9D8;
    color: #606060;
    padding: 10px 20px;
    display: flex;
    font-size: 13px;
    align-items: center;

    svg {
      color: #D84A1E;
      margin-right: 5px;
    }
  }

  &.toolbar {
    background: #FFF;
    box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.10);
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      background: transparent;
      font-size: 11px;
      color: $trabu-dark-teal;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      transition: 0.3s ease all;

      svg {
        width: 22px;
        color: $trabu-orange;
        margin-bottom: 4px;
      }

      &:disabled {
        opacity: 0.6;
      }
    }
  }
}