@import '../../colors';

.react-modal-sheet-container {
  background-image: url("../../../public/transparent-icon.png");
  background-size: 120%;
  background-repeat: no-repeat;
  background-position: -100px -100px;
}

.settings-modal {
  --padding-start: 30px;
  --padding-end: 30px;
  --padding-top: 30px;
}

.settings {
  padding: 30px;
  padding-top: 0;

  h1 {
    font-size: 24px;
    font-weight: 600;
    color: $trabu-teal;
    margin-top: 0;
  }

  label {
    font-size: 14px;
    font-weight: 600;
    color: $trabu-teal;
    display: block;
    margin-bottom: 5px;

    &:not(:first-of-type) {
      margin-top: 15px;
    }
  }

  input {
    font-size: 14px;
    color: #1a1a1a;

    &::placeholder {
      color: #999;
    }
  }

  .react-international-phone-input-container {
    width: 100%;
    box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.1);
    border: 0;
    border-radius: 10px;
    background: white;
    align-items: center;

    .react-international-phone-country-selector-button {
      border: 0;
      padding-left: 10px;
      background: white !important;
    }

    input {
      width: 100%;
      border: 0;
      font-size: 14px;
      padding: 14px 20px;
      padding-left: 5px;
      height: unset;
    }
  }

  .radio {
    font-size: 14px;
    color: $trabu-teal;
    padding: 10px;
    box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.1);
    background: white;
    border-radius: 5px;
    padding-left: 40px;
    margin-top: 10px;
    position: relative;
    border: 1px solid white;
    transition: 0.3s ease all;

    &:before {
      content: '';
      background: #E5E5E5;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 10px;
      left: 10px;
      border-radius: 4px;
    }

    &:after {
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 3px;
      position: absolute;
      left: 14px;
      top: 14px;
      background: $trabu-orange;
      opacity: 0;
      transition: 0.3s ease all;
    }

    &.selected {
      border: 1px solid $trabu-orange;

      &:after {
        opacity: 1;
      }
    }
  }
}